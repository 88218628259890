import styled from 'styled-components';

export const Main = styled.main`
    position: absolute;
    left: 260px;
    top: 138px;
    padding: 60px 40px;
    width: calc(100% - 260px);
    height: calc(100% - 138px);
    overflow-x: scroll;
    transition: all .6s ease;

    /* ScrollBar */
    &::-webkit-scrollbar {
        /* width: 8px; */
        height:10px;
    }
    
    &::-webkit-scrollbar-track {
        background: #fff;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: var(--n-color-3);
    }
`;