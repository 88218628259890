import React from 'react';
import { Link } from './styles';

const ButtonOne = ({width, height, borderRadius, children, ...props}) => {
    return (
        <Link 
            href="/*" 
            width={width} 
            height={height} 
            borderRadius={borderRadius}
            {...props}
        >
            {children}
        </Link>
    )
}

export default ButtonOne
