import React from 'react';
import { Button } from './styles';
import { Link } from 'react-router-dom';

const ButtonIcon = ( { children, onClick } ) => {
    return (
        <Button>
            <Link to="#" onClick={onClick}>
                { children }
            </Link>
        </Button>
    )
}

export default ButtonIcon
