import styled from 'styled-components';

export const Div = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    /* background: rgba(0, 0, 0, 0.5); */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .load{
        position: relative;
        top: -10px
    }

    p{
        color: #fff;
        font-size: 22px;
        animation: messageLoad 1s ease infinite;
    }

    @keyframes messageLoad{
        0%{
            opacity: 0;
        }
        50%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }
`;