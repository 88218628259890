import React from 'react';
import { Div } from './styles';

const InputFile = ({
    label, 
    name, 
    extraInformation, 
    value, 
    onChange, 
    onFocus, 
    active,
    required,
    reference,
    className
}) => {
    active = true;

    function validArchive(e){        
        let type = reference.current.files[0].type;

        if( !verifyType(type) ) {
            alert("Tipo de arquivo inválido, somente permitido jpg e png");
            reference.current.value = '';
            return false;
        }

        onChange(e);
    }

    function verifyType(type){
        if(type !== 'image/jpeg' && type !== 'image/jpg' && type !== 'image/png') return false;
        return true;
    }

    return (
        <Div activeItem={active} className={className}> 
            <label htmlFor={name}>{label}</label>

            <div className="input-file">
                <input 
                    onFocus={onFocus}
                    onChange={validArchive}
                    type="file" 
                    name={name} 
                    ref={reference}
                    className={required ? 'required' : ''}
                />

                <p 
                    className="value-input"
                    onClick={() => reference.current.click()}
                >
                    {value ? value : "Selecione um arquivo"}
                </p>
                
                <ul className="errors"></ul>
                {extraInformation && <ul className="extra-inf">{extraInformation}</ul>}
            </div>
            
        </Div>
    )
}

export default InputFile
