import React from 'react'
import Firebase from '../../../Config/firebase';

// Css
import { Form } from './styles';

// Js
import ValidationRequired from '../../../Infra/General/Validation/ValidationRequired';
import { useForm } from '../../../Components/General/Form/useForm'
import { addDeceased } from '../../../Global/api';
import { GlobalContext } from '../../../Global/GlobalContext';
import { uploadImage } from '../../../Infra/General/ImageHelpers';

// Components
import Input from '../../../Components/General/Form/Input'
import InputFile from '../../../Components/General/Form/InputFile';
import ButtonOne from '../../../Components/General/Buttons/ButtonOne';
import Textarea from '../../../Components/General/Form/Textarea';

const Add = () => {
    const { setLoading, showMessage } = React.useContext(GlobalContext);

    // Form
    const name = useForm();
    const image = useForm();
    const content = useForm();
    const birthday = useForm();
    const defeat = useForm();
    const formRef = React.useRef(null);
    const imageRef = React.useRef(null);

    function onSubmit(e){
        e.preventDefault();
        
        const validation = new ValidationRequired(formRef.current);

        if( validation.isValid() ) saveNewItem();
    }

    function saveNewItem(){
        setLoading(1);
        
        const imgUpload = uploadImage(imageRef.current.files[0], 'deceased');

        imgUpload.then(res => 
            
            addDeceased({
                "name": name.value,
                "image": res,
                "content": content.value,
                "birthday": birthday.value,
                "defeat": defeat.value
            })
            .then(res => {
                showMessage('Dados salvos com sucesso', 200);
                setLoading(0);

                    formRef.current.reset();
                    name.setValue('');
                    image.setValue('');
                    content.setValue('');
                    birthday.setValue('');
                    defeat.setValue('');

            })
            .catch(res => {
                setLoading(0);
                showMessage("Ocorreu um erro ao tentar salvar os dados", 400)
            })
        )
        .catch(res => {
            setLoading(0);
            showMessage("Ocorreu um erro ao tentar fazer o upload do arquivo", 400)
        })
    }

    return (
        <Form ref={formRef}>

            <h2>Adicionar Item</h2>

            <div className="inputs">
                <Input 
                    label="Nome"
                    name="name"
                    type="text"
                    validName="required"
                    className="input-standard"
                    {...name}
                />

                <Input 
                    label="Nascimento"
                    name="birthday"
                    type="text"
                    className="input-standard"
                    {...birthday}
                />

                <Input 
                    label="Falecimento"
                    name="defeat"
                    type="text"
                    className="input-standard"
                    {...defeat}
                />

                <Textarea 
                    label="Conteúdo"
                    name="content"
                    type="text"
                    className="input-standard"
                    {...content}
                />

                <InputFile 
                    name="Imagem"
                    label="image (169 x 166)" 
                    extraInformation=""
                    className="input-standard"
                    required="required"
                    reference={imageRef}
                    {...image}
                />
            </div>
            
            <ButtonOne
                width={200}
                height={50}
                onClick={onSubmit}
            >
                Enviar
            </ButtonOne>
        </Form>
    )
}

export default Add
