import firebase from '../Config/firebase';

const db = firebase.firestore();

// Banners
export const getBannerIntro = () => {
    const base = db.collection("bannerIntro");;
    const sort = base.orderBy("order", "asc");

    return sort.get().then(res => res.docs)
}

export const getSingleBannerIntro = (id) => {
    return db.collection("bannerIntro").doc(id).get();
}

export const addBannerIntro = (data) => {
    const timestamps = new Date();
    data['timestamps'] = timestamps;

    const allDocs = db.collection("bannerIntro").get().then(res => res.docs)

    return allDocs.then(res => {
        data['order'] = res.length + 1;
        db.collection("bannerIntro").doc().set(Object.assign({}, data))
    });
}

export const removeBannerIntro = (id) => {
    let collection = db.collection("bannerIntro").doc(id);    
    return collection.delete();
}

export const updateBannerIntro = (data, id) => {
    let collection = db.collection("bannerIntro").doc(id);    
    return collection.set(Object.assign({}, data), { merge: true });
}


// Deceaseds
export const getDeceaseds = () => {
    const base = db.collection("deceased");;
    const sort = base.orderBy("timestamps", "desc");

    return sort.get().then(res => res.docs)
}

export const addDeceased = (data) => {
    const timestamps = new Date();
    data['timestamps'] = timestamps;

    const allDocs = db.collection("deceased").get().then(res => res.docs)

    return allDocs.then(res => {
        data['order'] = res.length + 1;
        db.collection("deceased").doc().set(Object.assign({}, data))
    });
}

export const remvoeDeceased = (id) => {
    let collection = db.collection("deceased").doc(id);    
    return collection.delete();
}

export const getSingleDeceased = (id) => {
    return db.collection("deceased").doc(id).get();
}

export const updateDeceased = (data, id) => {
    let collection = db.collection("deceased").doc(id);    
    return collection.set(Object.assign({}, data), { merge: true });
}


// Partners
export const getPartners = () => {
    const base = db.collection("partners");;
    const sort = base.orderBy("timestamps", "desc");

    return sort.get().then(res => res.docs)
}

export const addPartner = (data) => {
    const timestamps = new Date();
    data['timestamps'] = timestamps;

    const allDocs = db.collection("partners").get().then(res => res.docs)

    return allDocs.then(res => {
        data['order'] = res.length + 1;
        db.collection("partners").doc().set(Object.assign({}, data))
    });
}

export const removePartner = (id) => {
    let collection = db.collection("partners").doc(id);    
    return collection.delete();
}

export const getSinglePartner = (id) => {
    return db.collection("partners").doc(id).get();
}

export const updatePartner = (data, id) => {
    let collection = db.collection("partners").doc(id);    
    return collection.set(Object.assign({}, data), { merge: true });
}


// Team
export const getTeam = () => {
    const base = db.collection("team");;
    const sort = base.orderBy("timestamps", "desc");

    return sort.get().then(res => res.docs)
}

export const addTeam = (data) => {
    const timestamps = new Date();
    data['timestamps'] = timestamps;

    const allDocs = db.collection("team").get().then(res => res.docs)

    return allDocs.then(res => {
        data['order'] = res.length + 1;
        db.collection("team").doc().set(Object.assign({}, data))
    });
}

export const removeTeam = (id) => {
    let collection = db.collection("team").doc(id);    
    return collection.delete();
}

export const getSingleTeam = (id) => {
    return db.collection("team").doc(id).get();
}

export const updateTeam = (data, id) => {
    let collection = db.collection("team").doc(id);    
    return collection.set(Object.assign({}, data), { merge: true });
}


// Message
export const getMessage = () => {
    const base = db.collection("message");;
    const sort = base.orderBy("timestamps", "desc");

    return sort.get().then(res => res.docs)
}

export const addMessage = (data) => {
    const timestamps = new Date();
    data['timestamps'] = timestamps;

    const allDocs = db.collection("message").get().then(res => res.docs)

    return allDocs.then(res => {
        data['order'] = res.length + 1;
        db.collection("message").doc().set(Object.assign({}, data))
    });
}

export const getSingleMessage = (id) => {
    return db.collection("message").doc(id).get();
}

export const updateMessage = (data, id) => {
    let collection = db.collection("message").doc(id);    
    return collection.set(Object.assign({}, data), { merge: true });
}


// General
export const moveData = (id, index, doc) => {
    let collection = db.collection(doc).doc(id);    
    return collection.set({
        order: index
    }, { merge: true });
}

export const getData = (doc) => {
    const base = db.collection(doc);;
    const sort = base.orderBy("order", "asc");

    return sort.get().then(res => res.docs)
}

export const removeData = async (id, doc) => {
    const allDocs = db
        .collection(doc)
        .orderBy("order", "asc")
        .get()
        .then(res => res.docs)

    let updateOrder = false;

    const process = await allDocs.then(res => {
        res.map(el => {
            if(updateOrder){
                db.collection(doc).doc(el.id).set({
                    order: parseInt(el.data().order) - 1
                }, { merge: true });
            }

            if(el.id === id){
                updateOrder = true;
                db.collection(doc).doc(id).delete();
            }
        })
    });
    

    return await Promise.resolve(process)
}