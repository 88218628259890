import styled from 'styled-components';

export const Button = styled.button`
    border: none;
    outline: none;
    transition: all .3s ease;
    font-size: 18px;
    padding: 0px;
    cursor: pointer;
    border-radius: ${props => props.borderRadius ? `${props.borderRadius}px` : '16px'};
    
    &:hover{
        opacity: 0.8;
        transform: scale(0.98);
    }

    &:active{
        transform: scale(0.9);
    }

    a{
        display: inline-block;
        width: 100%;
        height: 100%;
        width: 180px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
    }
`;