import React from 'react';
import { GlobalContext } from '../../../../Global/GlobalContext';
import { Div } from './styles';

const StandardTitle = () => {
    const {titlePage, descriptionPage} = React.useContext(GlobalContext);
    return (
        <Div>
            <h1>{titlePage}</h1>
            <p>{descriptionPage}</p>
        </Div>
    )
}

export default StandardTitle
