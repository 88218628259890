import React from 'react'
import BoxUser from '../../General/BoxUser'
import { HeaderDiv } from './styles';
import ButtonIcon from '../../General/Buttons/ButtonIcon';
import {ReactComponent as ExitIcon } from '../../../Assets/img/icons/Close.svg';
import StandardTitle from '../../General/Titles/StandardTitle';
import { NavLink, useNavigate } from 'react-router-dom';
import Firebase from '../../../Config/firebase';

const Header = () => {
    const navigation = useNavigate();
    const auth = Firebase.auth();

    function logout(e){
        e.preventDefault();    
        auth.signOut().then(res => {
            navigation('/');
        });
    }

    return (
        <HeaderDiv>
            <div className="left">
                <BoxUser />
            </div>

            <div className="center">
                <StandardTitle />
            </div>

            <div className="right">
               <ButtonIcon onClick={logout}>
                    <ExitIcon />
               </ButtonIcon>
            </div>
        </HeaderDiv>
    )
}

export default React.memo(Header);
