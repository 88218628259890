import React from 'react'
import Firebase from '../../../Config/firebase';

// Css
import { Form } from './styles';

// Js
import { useForm } from '../../../Components/General/Form/useForm'
import { addTeam } from '../../../Global/api';
import { uploadImage } from '../../../Infra/General/ImageHelpers';
import { GlobalContext } from '../../../Global/GlobalContext';
import ValidationRequired from '../../../Infra/General/Validation/ValidationRequired';

// Components
import Input from '../../../Components/General/Form/Input'
import InputFile from '../../../Components/General/Form/InputFile';
import ButtonOne from '../../../Components/General/Buttons/ButtonOne';
import Textarea from '../../../Components/General/Form/Textarea';

const Add = () => {
    const { setLoading, showMessage } = React.useContext(GlobalContext);

    // Form
    const name = useForm();
    const image = useForm();
    const content = useForm();
    const formRef = React.useRef(null);
    const imageRef = React.useRef(null);

    function onSubmit(e){
        e.preventDefault();
        const validation = new ValidationRequired(formRef.current);

        if(validation.isValid()) saveNewItem();
    }

    function saveNewItem(){
        setLoading(1);
        
        const imageUpload = uploadImage( imageRef.current.files[0], 'team' );

        imageUpload.then(res => 
            addTeam({
                "name": name.value,
                "image": res,
                "content": content.value
            })
            .then(res => {
                showMessage('Dados salvos com sucesso', 200);
                setLoading(0);

                formRef.current.reset();
                name.setValue('');
                image.setValue('');
                content.setValue('');
            })
            .catch(res => {
                setLoading(0);
                showMessage("Ocorreu um erro ao tentar salvar os dados", 400)
            })
        )
        .catch(err => {
            setLoading(0);
            showMessage("Ocorreu um erro ao tentar fazer o upload do arquivo", 400)
        });
    }

    return (
        <Form ref={formRef}>
            <h2>Adicionar Item</h2>

            <div className="inputs">
                <Input 
                    label="Nome"
                    name="name"
                    type="text"
                    validName="required"
                    className="input-standard"
                    {...name}
                />

                <InputFile 
                    name="Imagem"
                    label="image (358 x 166)" 
                    extraInformation=""
                    required="required"
                    reference={imageRef}
                    {...image}
                />

                <Textarea 
                    label="Conteúdo"
                    name="content"
                    type="text"
                    className="input-standard"
                    {...content}
                />
            </div>
            
            <ButtonOne
                width={200}
                height={50}
                onClick={onSubmit}
            >Enviar</ButtonOne>
        </Form>
    )
}

export default Add
