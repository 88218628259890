import React from 'react'

// Css
import { Div } from './styles';

// Components
import StandardTable from '../../../Components/General/Table/StandardTable';
import ButtonTwo from '../../../Components/General/Buttons/ButtonTwo';

const List = () => {
    const doc = 'partners';

    return (
        <Div className="person-container">
            <ButtonTwo to="adicionar">Adicionar item</ButtonTwo>
            
            <StandardTable doc={doc} />
        </Div>
    )    
}

export default List
