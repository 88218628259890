import React from 'react'
import { Div } from './styles';
import { directory_user_photo } from '../../../Config/env';

import Me from '../../../Assets/img/me.jpg';

const BoxUser = () => 
    <Div>
        <div className="wrap-img">
            <img src={ Me } alt=""/>
        </div>

        <div className="content">
            <span className="name">Admin</span>
            <span className="profession">agenciafenixconteudo@...</span>
        </div>
    </Div>


export default BoxUser
