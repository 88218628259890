import styled from 'styled-components';

export const HeaderDiv = styled.header`
    display: grid;
    grid-template-columns: minmax(auto, 260px) 3fr 1fr;
    padding: 35px 30px;
    border-bottom: 1px solid var(--n-color-4);
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 138px;
    background: #fff;
    z-index: 10;

    .left{
        position: relative;
        animation: toRight 1000ms ease forwards;
    }

    .center{
        padding: 0px 50px;
        position: relative;
        animation: toRight 1000ms ease forwards;
    }

    .right{
        display: flex;
        justify-content: flex-end;
        position: relative;
        animation: toRight 1000ms ease forwards;
    }



    @keyframes toRight{
        from{
            left: -100px;
            opacity: 0;
        }
        to{
            opacity: 1;
            left: 0px;
        }
    }
`;
