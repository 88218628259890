import React from 'react';

// Css
import { Form } from './styles';

// Js
import { useForm } from '../../../Components/General/Form/useForm'
import { GlobalContext } from '../../../Global/GlobalContext';
import { addMessage } from '../../../Global/api';
import ValidationRequired from '../../../Infra/General/Validation/ValidationRequired';

// Components
import Input from '../../../Components/General/Form/Input'
import ButtonOne from '../../../Components/General/Buttons/ButtonOne';
import Textarea from '../../../Components/General/Form/Textarea';
import Select from '../../../Components/General/Form/Select';

const Add = () => {
    const { setLoading, showMessage } = React.useContext(GlobalContext);

    // Form
    const formRef = React.useRef(null);
    const name = useForm();
    const city = useForm();
    const content = useForm();
    const socialMedia = useForm();
    const listSocialMedia = {
        "0": {
            "value": "instagram",
            "label": "Instagram"
        },
        "1": {
            "value": "facebook",
            "label": "Facebook"
        },
        "2": {
            "value": "twitter",
            "label": "Twitter"
        }
    }

    function onSubmit(e){
        e.preventDefault();
        const validation = new ValidationRequired(formRef.current);

        if(validation.isValid()) saveNewItem();
    }

    function saveNewItem(){
        setLoading(1);

        addMessage({
            "name": name.value,
            "city": city.value,
            "content": content.value,
            "socialmedia": socialMedia.value
        })
        .then(res => {
            showMessage('Dados salvos com sucesso', 200);
            setLoading(0);

                formRef.current.reset();
                name.setValue('');
                city.setValue('');
                content.setValue('');
                socialMedia.setValue('');
        })
        .catch(res => {
            setLoading(0);
            showMessage("Ocorreu um erro ao tentar salvar os dados", 400)
        });
    }

    return (
        <Form ref={formRef}>
            <h2>Adicionar Item</h2>

            <div className="inputs">
                <Input 
                    label="Nome"
                    name="name"
                    type="text"
                    validName="required"
                    className="input-standard"
                    {...name}
                />

                <Input 
                    label="Cidade"
                    name="city"
                    type="text"
                    className="input-standard"
                    {...city}
                />

                <Textarea 
                    label="Conteúdo"
                    name="content"
                    type="text"
                    className="input-standard"
                    {...content}
                />

                <Select 
                    label="Social Media"
                    name="socialMedia"
                    type="text"
                    validName="required"
                    className="input-standard"
                    list={listSocialMedia}
                    {...socialMedia}
                />

            </div>
            
            <ButtonOne
                width={200}
                height={50}
                onClick={onSubmit}
            >Enviar</ButtonOne>
        </Form>
    )
}

export default Add
