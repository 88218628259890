import ValidationForm from "../General/Validation/ValidationForm";

export default class LoginValid extends ValidationForm{
    constructor(form){
        super();
        this._form = form;
        this._validStart();
    }

    _validStart(){
        this.cleanError();

        let inputs = this._form.querySelectorAll('input, texarea');

        inputs.forEach(el => {
            let required = this.validRequired(el);
            
            if(required){
                this.validPassword(el);
                this.validEmail(el);
            }
        })
    }
}