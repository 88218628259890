import styled from 'styled-components';

export const Div = styled.div`
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    button{
        margin-bottom: 32px;
    }
`;