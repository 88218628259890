import React from 'react'
import { NavLink } from 'react-router-dom';
import { Nav } from './styles';

import {ReactComponent as HomeIcon} from '../../../Assets/img/icons/homeIcon.svg';
import {ReactComponent as GalleryIcon} from '../../../Assets/img/icons/galleryIcon.svg';
import {ReactComponent as InformationIcon} from '../../../Assets/img/icons/informationIcon.svg';
import {ReactComponent as ScheduleIcon} from '../../../Assets/img/icons/scheduleIcon.svg';
import {ReactComponent as BlogIcon} from '../../../Assets/img/icons/blogIcon.svg';

const MenuLeft = () => {
    return (
        <Nav>
            <ul>
                <h3>Conteúdo</h3>
                <li>
                    <NavLink to="/dashboard/banners">
                        <span>Banners</span>
                        <HomeIcon />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dashboard/falecidos">
                        <span>Falecidos</span>
                        <BlogIcon />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dashboard/parceiros">
                        <span>Parceiros</span>
                        <GalleryIcon />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dashboard/equipes">
                        <span>Equipe</span>
                        <ScheduleIcon />
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dashboard/mensagens">
                        <span>Mensagens</span>
                        <InformationIcon />
                    </NavLink>
                </li>
            </ul>
        </Nav>
    )
}

export default React.memo(MenuLeft)
