import React from 'react';
import { Div } from './styles';

const Input = ({
    label, 
    name, 
    extraInformation, 
    value, 
    onChange, 
    onFocus, 
    onBlur, 
    active,
    type,
    validName,
    className,
    onKeyDown
}) => {
    const ref = React.useRef(null);

    function focusInput(){
        ref.current.focus();
    }

    return (
        <Div activeItem={active} className={className}> 
            <label
                onClick={focusInput} 
                htmlFor={name}
            >{label}</label>

            <input 
                onFocus={onFocus}
                onBlur={onBlur} 
                onChange={onChange}
                type={type} 
                name={name} 
                ref={ref}
                value={value}
                className={`${validName}`}
                onKeyDown={onKeyDown}
            />
            
            <ul className="errors"></ul>
            {extraInformation && <ul className="extra-inf">{extraInformation}</ul>}
        </Div>
    )
}

export default Input
