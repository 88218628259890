import React from 'react';

export const useForm = (type) => {
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState('');
    const [active, setActive] = React.useState(false);

    React.useEffect(() => {
        if(value.length !== 0) setActive(true)
        if(value.length === 0) setActive(false)
    }, [value]);

    function onChange( {target } ){
        setValue(target.value);
    }

    function activeItem(input){
        setActive(true);
    }

    function removeActive(){
        if(value.length === 0) setActive(false)
    }

    return {
        value,
        setValue,
        onChange,
        active,
        setActive,
        onFocus: activeItem,
        onBlur: removeActive
    }
}