import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyConDgAcxhJBB63KeiJdm2uWS0VTEuei-E",
  authDomain: "smcc-b7ae8.firebaseapp.com",
  databaseURL: "https://smcc-b7ae8.firebaseio.com",
  projectId: "smcc-b7ae8",
  storageBucket: "smcc-b7ae8.appspot.com",
  messagingSenderId: "17234577009",
  appId: "1:17234577009:web:68db6e0cc93a459abdfdd0",
  measurementId: "G-284S6M91WJ"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;