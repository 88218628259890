import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

// Js
import ValidationRequired from '../../../Infra/General/Validation/ValidationRequired';
import { GlobalContext } from '../../../Global/GlobalContext';
import { updateBannerIntro, getSingleBannerIntro } from '../../../Global/api';
import { useForm } from '../../../Components/General/Form/useForm'
import { uploadImage } from '../../../Infra/General/ImageHelpers';

// Css
import { Form } from './styles';

// Components
import Input from '../../../Components/General/Form/Input'
import InputFile from '../../../Components/General/Form/InputFile';
import ButtonOne from '../../../Components/General/Buttons/ButtonOne';

const Update = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const { setLoading, showMessage } = React.useContext(GlobalContext);

    const arrPath = location.pathname.split('/');
    const id = arrPath[arrPath.length - 1];

    // Form
    const formRef = React.useRef(null);
    const name = useForm();
    const link = useForm();
    
    const imageDesktop = useForm();
    const [imageDesktopExtraInf, setImageDesktopExtraInf] = React.useState('');
    const imageDesktopRef = React.useRef(null);
    
    const imageMobile = useForm();
    const [imageMobileExtraInf, setImageMobileExtraInf] = React.useState('');
    const imageMobileRef = React.useRef(null);


    React.useEffect(() => {
        setLoading(1);

        getSingleBannerIntro(id)
            .then(res => {
                setLoading(0);

                    name.setValue( res.data().name );
                    link.setValue( res.data().link );
                    setImageDesktopExtraInf( res.data().imageDesktop )
                    setImageMobileExtraInf( res.data().imageMobile )
            })
            .catch(err => {
                setLoading(0);
                showMessage('O item foi removido com sucesso', 200);
                navigate('/dashboard/banners');
            });
    }, [])

    function onSubmit(e){
        e.preventDefault();
        
        const validation = new ValidationRequired( formRef.current );

        if( validation.isValid() ) saveNewItem();
    }

    function saveNewItem(){
        setLoading(1);
        let desktopFile = imageDesktopRef.current.files[0];
        let mobileFile = imageMobileRef.current.files[0];
        
        let desktopUpload = null;
        if(desktopFile) desktopUpload = uploadImage(desktopFile, 'banners');
        
        let mobileUpload = null;
        if(mobileFile) mobileUpload = uploadImage(mobileFile, 'banners');

        Promise.all( [ desktopUpload, mobileUpload ] ).then(res => {
            let desktopUrl = res[0];
            let mobileUrl = res[1];

            updateBannerIntro({
                "name": name.value,
                "link": link.value,
                "imageDesktop": desktopUrl ? desktopUrl : imageDesktopExtraInf,
                "imageMobile": mobileUrl ? mobileUrl : imageMobileExtraInf
            }, id)
            .then(res => {

                showMessage('Dados salvos com sucesso', 200);
                setLoading(0);
                
                if(desktopUrl) setImageDesktopExtraInf(desktopUrl);
                if(mobileUrl) setImageMobileExtraInf(mobileUrl);

            })
            .catch( err => {
                setLoading(0);
                showMessage("Ocorreu um erro ao tentar salvar os dados", 400)
            } );
        })
        .catch( err => {
            setLoading(0);
            showMessage("Ocorreu um erro ao tentar atualizar o item", 400)
        } );
    }

    return (
        <Form 
            ref={formRef}
        >

            <h2>Editar Item</h2>

            <div className="inputs">

                <Input 
                    label="Nome"
                    name="name"
                    type="text"
                    validName="required"
                    className="input-standard"
                    {...name}
                />

                <Input 
                    label="Link"
                    name="link"
                    type="text"
                    validName=""
                    className="input-standard"
                    {...link}
                />

                <InputFile 
                    name="imageDesktop"
                    label="Imagem Desktop (1920 x 630)" 
                    extraInformation=""
                    extraInformation={imageDesktopExtraInf}
                    reference={imageDesktopRef}
                    {...imageDesktop}
                />

                <InputFile 
                    name="imageMobile"
                    label="Imagem Mobile (790 x 400)" 
                    extraInformation=""
                    extraInformation={imageMobileExtraInf}
                    reference={imageMobileRef}
                    {...imageMobile}
                />

            </div>
            
            <ButtonOne
                width={200}
                height={50}
                onClick={onSubmit}
            >
                Enviar
            </ButtonOne>
        </Form>
    )
}

export default Update
