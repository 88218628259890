import React from 'react';
import { Div } from './styles';

const Input = ({
    label, 
    name, 
    extraInformation, 
    value, 
    onChange, 
    onFocus, 
    onBlur, 
    active,
    type,
    validName,
    className,
    onKeyDown,
    list
}) => {
    const ref = React.useRef(null);

    function focusInput(){
        ref.current.focus();
    }

    return (
        <Div activeItem={active} className={className}> 
            <label
                onClick={focusInput} 
                htmlFor={name}
            >{label}</label>

            <select 
                onFocus={onFocus}
                onBlur={onBlur} 
                onChange={onChange}
                type={type} 
                name={name} 
                ref={ref}
                value={value}
                className={`${validName}`}
                onKeyDown={onKeyDown}
            >
                <option value="" selected disabled>Selecione um item</option>
                {
                    list && Object.values(list).map((el, index) => 
                        <option key={index} value={el.value}>{el.label}</option>    
                    )
                }
            </select>
            
            <ul className="errors"></ul>
            {extraInformation && <ul className="extra-inf">{extraInformation}</ul>}
        </Div>
    )
}

export default Input
