import React from 'react'
import { Route, Routes } from 'react-router-dom';

// Js
import { GlobalContext } from '../../Global/GlobalContext';

// Components
import Add from './Add';
import List from './List';
import Remove from './Remove';
import Update from './Update';

const Banners = () => {
    const {setHeaderData} = React.useContext(GlobalContext);

    React.useState(() => {
        setHeaderData("Banners", "Adicione, edite e remova seus banners");
    })

    return (
        <Routes>
            <Route path="/" exact element={<List />} />
            <Route path="/adicionar" element={<Add />} />
            <Route path="/atualizar/:id" element={<Update />} />
            <Route path="/remover/:id" element={<Remove />} />
        </Routes>
    )    
}

export default Banners
