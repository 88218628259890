export default class ValidationForm{
    validRequired(el){
        if(el.classList.contains('required') && el.value.length == 0){
            this.addError(el, '*Campo obrigatório')
            return false;
        }

        return true;
    }

    validPassword(el){
        if(el.type === 'password' && el.value.length < 8)
            this.addError(el, '*A senha deve conter no mínimo 8 caracteres')
    }

    validEmail(el){
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const email = el.value;

        if(el.classList.contains('email') && !re.test(String(email).toLowerCase()))
            this.addError(el, '*E-mail inválido')
    }

    cleanError(){
        let ulErrors = this._form.querySelectorAll('.errors');
            ulErrors.forEach(el =>{
                el.classList.remove('error');
                el.innerHTML = "";
            })

        let errors = this._form.querySelectorAll('.error');
            errors.forEach(el =>{
                el.classList.remove('error');
                el.innerHTML = "";
            })
    }

    addError(el, msg){
        const ul = el.parentElement.querySelector('.errors');
        const error = document.createElement('li');

            error.innerHTML = msg;
            ul.appendChild(error);
            el.classList.add('error');
    }

    isValid(){
        if(this._form.querySelectorAll(".error").length) return false;
        return true;
    }
    
}