import styled from 'styled-components';

export const Div = styled.div`
    display: flex;
    align-items: center;
    transition: all .3s ease;

    .wrap-img{
        border-radius: 16px;
        overflow: hidden;
        margin-right: 18px;
        transition: all .3s ease;
        opacity: 0.8;
    }

    .content{
        display: flex;
        flex-direction: column;
    }

    .name{
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
        margin-bottom: 8px;
        color: var(--n-color-1);
    }

    .profession{
        font-size: 16px;
        line-height: 16px;
        color: var(--n-color-3);
    }
`;