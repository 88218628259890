import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import GlobalStyles from './Global/GlobalStyles';
import { GlobalDataContext } from './Global/GlobalContext';

import LoaderPage from './Components/Helpers/LoaderPage';
import TopMessage from './Components/Helpers/TopMessage';
import PrivateRoute from './Components/Helpers/PrivateRoute';

import Login from './Pages/Login';
import MainContent from './Components/Structure/MainContent';

const App = () => {
  return(
    <BrowserRouter>
      <GlobalDataContext>

        <GlobalStyles />

        <LoaderPage />
        <TopMessage />
        
        <Routes>
          <Route path="/" exact element={<Login />} />
          <PrivateRoute path="/dashboard/*" element={<MainContent />} />
        </Routes>

      </GlobalDataContext>

    </BrowserRouter>
  )
}

export default App;
