import styled from 'styled-components';

export const Div = styled.div`
    position: relative;
    width: 300px;

    input{
        width: 100%;
        border: 3px solid var(--n-color-4);
        border-radius: 3.5px;
        height: 54px;
        padding: 0px 16px;
        margin-bottom: 6px;
    }

    label{
        position: absolute;
        left: 0;
        top: 0;
        font-size: ${props => props.activeItem ? '12px' : '16px'};
        top: ${props => props.activeItem ? '-6px' : '16px'};
        /* top: 16px; */
        left: 14px;
        background: #fff;
        padding: 0px 5px;
        transition: all .3s ease;
        color: ${props => props.activeItem ? 'var(--color-5)' : 'var(--n-color-3)'};
        font-weight: 600;
    }

    input.error{
        border: 3px solid red;
    }

    .extra-inf{
        font-size: 14px;
        padding-left: 16px;
        color: var(--color-5);
        font-weight: 600;
    }
    .errors{
        font-size: 14px;
        padding-left: 16px;
        font-weight: 600;
        color: var(--color-3) !important;
    }
`;