import React from 'react'
import { Routes } from 'react-router-dom';

// Css
import { Main } from './styles';

// Components
import Header from '../Header';
import MenuLeft from '../MenuLeft';
import PrivateRoute from '../../Helpers/PrivateRoute';

import Banners from '../../../Pages/Banners';
import Partners from '../../../Pages/Partners';
import Teams from '../../../Pages/Teams';
import Deceaseds from '../../../Pages/Deceaseds';
import Messages from '../../../Pages/Message';

const MainContent = ({children}) => {
    return (
        <div>
            
            <Header />
            <MenuLeft />

            <Main className="content-active">
                <Routes>
                    <PrivateRoute path="/banners/*" element={<Banners />} />
                    <PrivateRoute path="/falecidos/*" element={<Deceaseds />} />
                    <PrivateRoute path="/parceiros/*" element={<Partners />} />
                    <PrivateRoute path="/equipes/*" element={<Teams />} />
                    <PrivateRoute path="/mensagens/*" element={<Messages />} />
                </Routes>
            </Main>
        </div>
    )
}

export default MainContent
