import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

// Css
import { Form } from './styles';

// Js
import { useForm } from '../../../Components/General/Form/useForm'
import { updatePartner, getSinglePartner } from '../../../Global/api';
import { GlobalContext } from '../../../Global/GlobalContext';
import { uploadImage } from '../../../Infra/General/ImageHelpers';
import ValidationRequired from '../../../Infra/General/Validation/ValidationRequired';

// Components
import Input from '../../../Components/General/Form/Input'
import InputFile from '../../../Components/General/Form/InputFile';
import ButtonOne from '../../../Components/General/Buttons/ButtonOne';

const Update = () => {
    const { setLoading, showMessage } = React.useContext(GlobalContext);

    const navigate = useNavigate();
    const location = useLocation();

    const arrPath = location.pathname.split('/');
    const id = arrPath[arrPath.length - 1];

    // Form
    const formRef = React.useRef(null);
    const name = useForm();
    const logo = useForm();
    const logoRef = React.useRef(null);
    const [logoExtraInf, setLogoExtraInf] = React.useState('');

    React.useEffect(() => {
        setLoading(1);

        getSinglePartner(id)
            .then(res => {
                setLoading(0);
                
                name.setValue(res.data().name);
                setLogoExtraInf(res.data().logo)
            })
            .catch(res => {
                setLoading(0);
                showMessage('O item foi removido com sucesso', 200);
                navigate('/dashboard/parceiros');
            });
    }, [])

    function onSubmit(e){
        e.preventDefault();
        const validation = new ValidationRequired(formRef.current);

        if(validation.isValid()) saveNewItem();
    }

    function saveNewItem(){
        setLoading(1);
        
        let logoFile = logoRef.current.files[0];
        let logoUpload = null;
        if(logoFile) logoUpload = uploadImage(logoFile, 'partners');

        Promise.all( [ logoUpload ] ).then(image => 
            updatePartner({
                "name": name.value,
                "logo": image[0] ? image[0] : logoExtraInf
            }, id)
            .then(res => {
                showMessage('Dados salvos com sucesso', 200);
                setLoading(0);
                
                if(image[0]) setLogoExtraInf(image[0]);
            })
            .catch( err => {
                setLoading(0);
                showMessage("Ocorreu um erro ao tentar salvar os dados", 400)
            } )
        )
        .catch( err => {
            setLoading(0);
            showMessage("Ocorreu um erro ao tentar atualizar o item", 400)
        } );
    }

    return (
        <Form ref={formRef}>
            <h2>Editar Item</h2>

            <div className="inputs">
                <Input 
                    label="Nome"
                    name="name"
                    type="text"
                    validName="required"
                    className="input-standard"
                    {...name}
                />

                <InputFile 
                    name="logo"
                    label="Logo (217 x --)" 
                    extraInformation={logoExtraInf}
                    reference={logoRef}
                    {...logo}
                />
            </div>
            
            <ButtonOne
                width={200}
                height={50}
                onClick={onSubmit}
            >
                Enviar
            </ButtonOne>
        </Form>
    )
}

export default Update
