import React from 'react';
import { GlobalContext } from '../../../Global/GlobalContext';
import { Div } from './styles';

const TopMessage = () => {
    const {message, stateMessage, showMessage} = React.useContext(GlobalContext);

    React.useEffect(() => {
        if(message) setTimeout(()=> showMessage('', 0), 5000)
    }, [message, showMessage])
    
    if(!message) return null;
    
    return (
        <Div stateMessage={stateMessage}>
            {message}

            <div onClick={() => showMessage('', 0)}>
                <button className="close">X</button>
            </div>
        </Div>
    )
}

export default TopMessage
