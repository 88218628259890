import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-items: center;
    max-width: 620px;
    margin: 0px auto 0px;
    align-items: flex-end;

    h2{
        margin-bottom: 32px;
        align-self: flex-start;
    }
    .inputs{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px 20px;
        max-width: 620px;
        margin-bottom: 20px;
        margin: 0 auto 20px;
    }

    .input-standard:nth-child(3){
        margin-top: -80px;
    }

    button{
        align-self: flex-end;
    }
`;