import styled from 'styled-components';

export const Link = styled.a`
    width: ${props => props.width + 'px'};
    height: ${props => props.height + 'px'};
    background: var(--color-4);
    color: #fff;
    border: none;
    outline: none;
    border-radius: ${props => props.borderRadius ? `${props.borderRadius}px` : '16px'};
    transition: all .3s ease;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
        opacity: 0.9;
        transform: scale(0.98);
    }

    &:active{
        transform: scale(0.9);
    }
`;