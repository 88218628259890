import styled from 'styled-components';

export const Nav = styled.nav`
    padding: 20px 16px;
    border-right: 1px solid var(--n-color-4);
    position: fixed;
    left: 0px;
    top: 138px;
    height: calc(100% - 138px);
    width: 260px;
    background: #fff;
    z-index: 10;
    animation: toRight 1000ms ease forwards;

    @keyframes toRight{
        from{
            left: -50px;
            opacity: 0;
        }
        to{
            opacity: 1;
            left: 0px;
        }
    }

    h3{
        color: var(--n-color-3);
        font-size: 20px;
        font-weight: 100;
        margin-bottom: 20px;
    }

    ul{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    li + li{
        margin-top: 10px;
    }

    li{
        width: 100%;
    }

    a{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: var(--n-color-3);
        font-size: 17px;
        padding: 20px 16px;
        transition: all .3s ease;
        border-radius: 16px;
    }

    a:not(.active){
        &:hover{
            color: var(--n-color-1);

            svg path{
                fill: var(--n-color-1);
            }
        }
    }

    .active{
        background: var(--n-color-1);
        padding: 20px 16px;
        border-radius: 16px;
        color: #fff;
        transition: all .3s ease;
        position: relative;

        svg path{
            fill: #fff;
        }

        &:hover{
            opacity: 0.9;
            transform: scale(0.98);
        }

        &:active{
            transform: scale(0.9);
        }


        /* animation: menuActive .6s ease-in-out; */
    }

    svg path{
        transition: all .3s ease;
    }

`;