import React from 'react'
import { useNavigate } from 'react-router-dom';
import Firebase from '../../Config/firebase';

// Css
import { Div } from './styles';

// Js
import LoginValid from '../../Infra/Login/LoginValid';
import { GlobalContext } from '../../Global/GlobalContext';
import { useForm } from '../../Components/General/Form/useForm';

// Components
import { ReactComponent as Loader } from '../../Assets/img/load/load-1.svg';
import ButtonOne from '../../Components/General/Buttons/ButtonOne';
import Input from '../../Components/General/Form/Input';

const Login = () => {
    const navigate = useNavigate();

    const auth = Firebase.auth().setPersistence(Firebase.auth.Auth.Persistence.SESSION);

    const [loading, setLoading] = React.useState(0);
    const { showMessage } = React.useContext(GlobalContext);

    const formRef = React.useRef(null);
    const email = useForm();
    const password = useForm();

    function login(e){
        if(e !== undefined) e.preventDefault();

        const validator = new LoginValid(formRef.current);
        
        if(validator.isValid()){
            setLoading(1);

            auth
                .then(() => {
                    return Firebase.auth().signInWithEmailAndPassword(email.value, password.value);
                })
                .then(res => {
                    setLoading(0);
                    navigate('/dashboard/banners');
                })  
                .catch(err => {
                    setLoading(0);
                    showMessage("Usuário ou senha inválido", 400)
                });
        }
    }

    return (
        <Div loading={loading}>
            <form 
                ref={formRef} 
                className="content"
            >
               <h1>Login</h1>
               <p>Administre seu projeto online</p>

               <Input 
                    label="E-mail"
                    name="email"
                    type="text"
                    validName="required email"
                    onKeyDown={(e) => e.keyCode === 13 ? login() : null}
                    className="input-standard"
                    {...email}
               />
               
                <Input 
                    type="password"
                    label="Senha"
                    name="password"
                    validName="required"
                    className="input-standard"
                    onKeyDown={(e) => e.keyCode === 13 ? login() : null}
                    {...password}
               />

                <ButtonOne
                    width="200"
                    height="50"
                    onClick={login}
                    className="btn-load"
                >
                    {loading ? <Loader /> : 'Entrar'}
                </ButtonOne>
           </form>
        </Div>
    )
}

export default Login
