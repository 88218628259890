import styled from 'styled-components';

export const Div = styled.div`
    h1{
        font-size: 42px;
        line-height: 42px;
        margin-bottom: 10px;
    }

    p{
        font-size: 17px;
        line-height: 17px;
        color: var(--n-color-3);
    }
`;