import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from './styles';

const ButtonTwo = ({children, to}) => {
    return (
        <Button>
            <Link to={to}>
                {children}
            </Link>
        </Button>
    )
}

export default ButtonTwo;
