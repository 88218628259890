import styled from 'styled-components';

export const Div = styled.div`
    position: fixed;
    left: 50%;
    top: 10%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    width: 600px;
    margin-left: -300px;
    /* height: 70px; */
    border-radius: 10px;
    font-size: 18px;
    padding: 20px 20px 20px 32px;
    animation: showMessage .6s ease;

    background: ${props => {
        switch(props.stateMessage){
            case 200:
                return "var(--color-4)";
            case 400:
                return "var(--color-3)";
            default:
                return "var(--color-1)";
        }
    }};

    color: ${props => {
        switch(props.stateMessage){
            case 200:
                return "#fff";
            case 400:
                return "#fff";
            default:
                return "var(--n-color-1)";
        }
    }};

    @keyframes showMessage{
        from{
            top: -0px;
            opacity: 0;
        }
        to {
            top: 10%;
            opacity: 1;  
        }
    }

    .close{
        border: 3px solid #fff;
        border-radius: 50%;
        display: inline-block;
        outline: none;
        background: transparent;
        padding: 10px;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding-top: 12px;
        cursor: pointer;
        transition: all .3s ease;

        &:hover{
            opacity: 0.7;
            transform: scale(0.9);
        }

        &:active{
            transform: scale(0.5);
        }
    }
`;