import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

// Js
import { removeData, remvoeDeceased } from '../../../Global/api';
import { GlobalContext } from '../../../Global/GlobalContext';

const Remove = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {showMessage, setLoading} = React.useContext(GlobalContext);
    
    const arrPath = location.pathname.split('/');
    const id = arrPath[arrPath.length - 1];

    React.useEffect(() => {
        setLoading(1);
        
        removeData(id, 'deceased')
            .then(res => {
                setLoading(0);
                showMessage('O item foi removido com sucesso', 200);
                navigate('/dashboard/falecidos');
            })
            .catch(res => {
                setLoading(0);
                showMessage("Ocorreu um erro ao tentar remover o item", 400)
                navigate('/dashboard/falecidos');
            });
    }, [])
    
    return null;
}

export default Remove
