import styled from 'styled-components';

export const Div = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(34,193,195);
    background: linear-gradient(45deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);

    .content{
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -215px;
        margin-top: -100px;
        opacity: 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 48px 48px 54px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        animation: showForm .6s forwards;
    }
    
    @keyframes showForm{
        to{
            margin-top: -220px;
            opacity: 1;
        }
    }

    h1{
        margin-bottom: 4px;
        font-size: 32px;
    }

    p{
        font-size: 16px;
        color: var(--n-color-3);
        margin-bottom: 24px;
    }

    .input-standard{
        margin-bottom: 20px;
    }


    .btn-load{
        transition: all .3s ease;
        
        width: ${props => props.loading ? '50px' : '200px'};
        height: ${props => props.loading ? '50px' : '50px'};
        border-radius: ${props => props.loading ? '50%' : '16px'};

        pointer-events:  ${props => props.loading ? 'none' : 'auto'};
        cursor:  ${props => props.loading ? 'default' : 'pointer'};
        opacity:  ${props => props.loading ? '0.7' : '1'};
    }
`;