import React from 'react';
import { useNavigate } from 'react-router-dom';

// Css
import { Table } from './styles';

// Js
import {getFirstParentMatch} from '../../../../Infra/General/Helpers';

// Components
import {ReactComponent as RemoveIcon} from '../../../../Assets/img/icons/remove-1.svg';
import { getData, moveData } from '../../../../Global/api';
import { GlobalContext } from '../../../../Global/GlobalContext';

const StandardTable = ({doc}) => {
    const navigate = useNavigate();
    const [tableContent, setTableContent] = React.useState(null);
    const [update, setUpdate] = React.useState(0);
    const {setLoading, showMessage} = React.useContext(GlobalContext);

    React.useEffect(() => {
        setLoading(1)

        getData(doc)
            .then(res => {
                setTableContent(res)
                setLoading(0)
            })
            .catch(res => {
                setLoading(0);
                showMessage("Ocorreu um erro ao tentar listar os dados", 400)
            });
    }, [update]);
    
    function selectItem(e){
        e.preventDefault();

        const target = e.target;
        const tr = target.parentElement;
        const id = tr.dataset.id;
    
        if(id) navigate('atualizar/' + id);
    }

    function removeItem(e){
        e.preventDefault();

        let ok = window.confirm("Pressione Ok para confirmar a remoção do item")
        
        if(ok){
            const target = e.target;
            const removeItem = getFirstParentMatch(target, 'tr');
            const id = removeItem.dataset.id;
            
            if(id) navigate('remover/' + id)
        }
    }

    function toUp(e){
        e.preventDefault();
        

        const trTarget = getFirstParentMatch(e.target, 'tr');
        const trTargetId = trTarget.dataset.id;
        const trTargetIndex = parseInt(trTarget.dataset.index);
        
        if(trTargetIndex === 1) return;
        
        setLoading(1)
        const targetPromise = moveData(trTargetId, (trTargetIndex - 1), doc)

        const trPrevious = trTarget.previousElementSibling;
        const trPreviousId = trPrevious.dataset.id;
        const trPreviousIndex = parseInt(trPrevious.dataset.index);
        
        const target2Promise = moveData(trPreviousId, (trPreviousIndex + 1), doc)

        Promise.all([targetPromise, target2Promise]).then(() => {
            setUpdate(update + 1);
        });
    }

    function toDown(e){
        e.preventDefault();
        
        const trTarget = getFirstParentMatch(e.target, 'tr');
        const trTargetId = trTarget.dataset.id;
        const trTargetIndex = parseInt(trTarget.dataset.index);

        if(trTargetIndex === tableContent.length) return;
        
        setLoading(1)
        const targetPromise = moveData(trTargetId, (trTargetIndex + 1), doc)

        const trNext = trTarget.nextElementSibling;
        const trNextId = trNext.dataset.id;
        const trNextIndex = parseInt(trNext.dataset.index);
        const target2Promise = moveData(trNextId, (trNextIndex - 1), doc)

        Promise.all([targetPromise, target2Promise]).then(() => {
            setUpdate(update + 1);
        });
    }

    return (
        <Table>
            <thead>
                <tr>
                    <th>Indice</th>
                    <th>Nome</th>
                    <th>Deletar</th>
                    <th>Posição</th>
                </tr>
            </thead>

            <tbody>
                {tableContent && tableContent.map((el, index) => {
                    let data = el.data();

                    return(
                        <tr data-id={el.id} data-index={data.order} onClick={selectItem} key={index}>
                            {/* <td>{(index + 1)}</td> */}
                            <td>{data.order}</td>
                            <td>{data.name}</td>
                            <td><a href="" onClick={removeItem}><RemoveIcon /></a></td>
                            <td className="arrow-td">
                                <button className="arrow-up" onClick={toUp}></button>
                                <button className="arrow-down" onClick={toDown}></button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    )
}

export default StandardTable
