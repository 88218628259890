import styled from 'styled-components';

export const Button = styled.button`
    border: none;
    outline: none;
    padding: 0px;
    border-radius: 12px;
    width: 46px;
    height: 46px;
    overflow: hidden;
    transition: all .3s ease;

    &:hover{
        transform: scale(0.9);
        opacity: 0.75;
    }

    a{
        display: inline-block;
        width: 46px;
        height: 46px;
        background: var(--n-color-4);
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;