import React from 'react'

// Js
import ValidationRequired from '../../../Infra/General/Validation/ValidationRequired';
import { GlobalContext } from '../../../Global/GlobalContext';
import { useForm } from '../../../Components/General/Form/useForm'
import { addBannerIntro } from '../../../Global/api';
import { uploadImage } from '../../../Infra/General/ImageHelpers';

// Css
import { Form } from './styles';

// Components
import Input from '../../../Components/General/Form/Input'
import InputFile from '../../../Components/General/Form/InputFile';
import ButtonOne from '../../../Components/General/Buttons/ButtonOne';

const Add = () => {
    const { setLoading, showMessage } = React.useContext(GlobalContext);

    // Form
    const name = useForm();
    const link = useForm();
    
    const imageDesktop = useForm();
    const imageDesktopRef = React.useRef(null);
    
    const imageMobile = useForm();
    const imageMobileRef = React.useRef(null);

    const formRef = React.useRef(null);

    function onSubmit(e){
        e.preventDefault();

        const validation = new ValidationRequired(formRef.current);
        
        if(validation.isValid()) saveNewItem();
    }

    function saveNewItem(){
        setLoading(1);
        
        const fileDesktop = uploadImage(imageDesktopRef.current.files[0], 'banners');
        const fileMobile = uploadImage(imageMobileRef.current.files[0], 'banners');

        Promise.all( [ fileDesktop, fileMobile ] ).then( res => 

            addBannerIntro({
                "name": name.value,
                "link": link.value,
                "imageDesktop": res[0],
                "imageMobile": res[1]
            })
            .then(res => {
                showMessage('Dados salvos com sucesso', 200);
                setLoading(0);
                
                    formRef.current.reset();
                    name.setValue('');
                    link.setValue('');
                    imageDesktop.setValue('');
                    imageMobile.setValue('');

            }).catch(err =>{
                setLoading(0);
                showMessage("Ocorreu um erro ao tentar salvar os dados", 400)
            })

        ).catch(err =>{
            setLoading(0);
            showMessage("Ocorreu um erro ao tentar fazer o upload do arquivo", 400)
        });
    }

    return (
        <Form 
            ref={formRef}
        >
            
            <h2>Adicionar Item</h2>

            <div className="inputs">
                <Input 
                    label="Nome"
                    name="name"
                    type="text"
                    validName="required"
                    className="input-standard"
                    {...name}
                />

                <Input 
                    label="Link"
                    name="link"
                    type="text"
                    validName=""
                    className="input-standard"
                    {...link}
                />

                <InputFile 
                    name="imageDesktop"
                    label="Imagem Desktop (1920 x 630)" 
                    extraInformation=""
                    required="required"
                    reference={imageDesktopRef}
                    {...imageDesktop}
                />

                <InputFile 
                    name="imageMobile"
                    label="Imagem Mobile (790 x 400)" 
                    extraInformation=""
                    required="required"
                    reference={imageMobileRef}
                    {...imageMobile}
                />
            </div>
            
            <ButtonOne
                width={200}
                height={50}
                onClick={onSubmit}
            >
                Enviar
            </ButtonOne>
        </Form>
    )
}

export default Add
