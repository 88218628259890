import React from 'react'

// Css
import { Form } from './styles';

// Js
import { useForm } from '../../../Components/General/Form/useForm'
import { GlobalContext } from '../../../Global/GlobalContext';
import { addPartner } from '../../../Global/api';
import { uploadImage } from '../../../Infra/General/ImageHelpers';
import ValidationRequired from '../../../Infra/General/Validation/ValidationRequired';

// Components
import Input from '../../../Components/General/Form/Input'
import InputFile from '../../../Components/General/Form/InputFile';
import ButtonOne from '../../../Components/General/Buttons/ButtonOne';

const Add = () => {
    const { setLoading, showMessage } = React.useContext(GlobalContext);

    // Form
    const name = useForm();
    const logo = useForm();
    const formRef = React.useRef(null);
    const logoRef = React.useRef(null);

    function onSubmit(e){
        e.preventDefault();
        const validation = new ValidationRequired(formRef.current);

        if(validation.isValid()) saveNewItem();
    }

    function saveNewItem(){
        setLoading(1);
        
        const logoUpload = uploadImage(logoRef.current.files[0], 'partners');

        logoUpload.then(res => 
            addPartner({
                "name": name.value,
                "logo": res
            })
            .then(res => {
                showMessage('Dados salvos com sucesso', 200);
                setLoading(0);

                formRef.current.reset();
                name.setValue('');
                logo.setValue('');
            })
            .catch(err => {
                setLoading(0);
                showMessage("Ocorreu um erro ao tentar salvar os dados", 400)
            })
        )
        .catch(err => {
            setLoading(0);
            showMessage("Ocorreu um erro ao tentar atualizar o item", 400)
        });
    }

    return (
        <Form ref={formRef}>
            <h2>Adicionar Item</h2>

            <div className="inputs">
                <Input 
                    label="Nome"
                    name="name"
                    type="text"
                    validName="required"
                    className="input-standard"
                    {...name}
                />

                <InputFile 
                    name="logo"
                    label="Logo (217 x --)" 
                    extraInformation=""
                    required="required"
                    reference={logoRef}
                    {...logo}
                />
            </div>
            
            <ButtonOne
                width={200}
                height={50}
                onClick={onSubmit}
            >
                Enviar
            </ButtonOne>
        </Form>
    )
}

export default Add
