import React, { useContext } from "react";
import { Navigate, Route } from "react-router-dom";
import { GlobalContext } from "../../../Global/GlobalContext";


const PrivateRoute = (props) => {
  const { currentUser } = useContext(GlobalContext);

  const authUser = Object
    .keys(window.sessionStorage)
    .filter(item => item.startsWith('firebase:authUser'))[0];

  const sessionUser = JSON.parse(window.sessionStorage.getItem(authUser));

  if(sessionUser) return <Route {...props} />;
  
  if(currentUser === null ) return <Navigate to="/" />;

  return null;
};


export default PrivateRoute