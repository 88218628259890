import ValidationForm from "./ValidationForm";

export default class ValidationRequired extends ValidationForm{
    constructor(form){
        super();
        this._form = form;
        this._validStart();
    }

    _validStart(){
        this.cleanError();

        let inputs = this._form.querySelectorAll('input, texarea');
            inputs.forEach(el => this.validRequired(el) );
    }
}