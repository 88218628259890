import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    max-width: 960px;
    margin: 0 auto;

    td,  th {
        padding: 12px 0px;
        text-align: center !important;
    }
    td{
        vertical-align: middle;
    }

    tbody tr{
        cursor: pointer;
    }

    tbody a{
        transition: all .2s ease;

        &:hover{
            opacity: 0.3;
        }

        svg{
            transition: all .3s ease;
            
            &:active {
                transform: scale(0.5);
            }
        }

        &:active {
            svg{
                transform: scale(0.5);
            }
        }
    }

     tr:nth-child(even){
         background-color: #f2f2f2;
     }

     tr:hover {
        background-color: #ddd;
    }

    th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #4CAF50;
        color: white;
    }

    svg{
        max-width: 24px;
        fill: red;
        position: relative;
        top: 4px;
    }

    /* Arrows */
    .arrow-td{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 54px;

        button{
            margin: 0px;
            cursor: pointer;
            transition: all .2s ease;
            opacity: 0.5;

            &:hover{
                opacity: 0.1;
            }

            &:active{
                padding: 3px;
            }
        }
    }

    .arrow-up {
        border: solid #333;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        margin-bottom: 2px;

        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);

        background: none;
        outline: none;
        position: relative;
        top: -2px;
    }

    .arrow-down {
        border: solid #333;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;

        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);

        background: none;
        outline: none;
        position: relative;
        bottom: -2px;
    }
`;