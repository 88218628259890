import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    /* Fonts */
    @import url('https://fonts.googleapis.com/css2?family=Bentham&family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    /* Reset */
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    html{
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    body{
        --type-first: 'Libre Franklin', sans-serif;

        --color-1: #828282;
        --color-2: #43A047;
        --color-3: #EB5757;
        --color-4: #6FCF97;
        --color-5: #9B51E0;
        --color-6: #56CCF2;
        --color-7: #F2994A;
        --color-8: #2F80ED;

        --n-color-1: #262628;
        --n-color-2: #9593A0;
        --n-color-3: #BAB9C1;
        --n-color-4: #F3F4F6;

        margin: 0px;
        color: #333331;
        font-family: var(--type-first);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        line-height: 1.5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p{
        margin: 0;
    }

    ul, li{
        list-style: none;
        padding: 0px;
        margin: 0px;
    }

    img{
        display: block;
        max-width: 100%;
    }

    button,
    input{
        display: block;
        font-size: 1rem;
        font-family: var(--type-first);
        color: #333331;
    }

    a{
        text-decoration: none;
    }

    /* Containers */
    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 576px) {
        .container, .container-sm {
            width: 100%;
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .container, .container-sm, .container-md {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        .container, .container-sm, .container-md, .container-lg {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .container, .container-sm, .container-md, .container-lg, .container-xl {
            max-width: 1140px;
        }
    }

    /* ScrollBar */
    /* body::-webkit-scrollbar {
        width: 8px;
    }
    
    body::-webkit-scrollbar-track {
        background: #fff;
    }
    
    body::-webkit-scrollbar-thumb {
        background-color: var(---n-color-1);
    } */
    
`;